import { LoginService } from './services/login.service';
import { UserCheckoutPage } from './user/user-checkout/user-checkout.page';
import { ModalController, AlertController } from '@ionic/angular';
import { Component, Renderer2, AfterViewInit } from '@angular/core';
import { DataService } from './app.service'; 
import axios from 'axios';
import config from '../config';
import { Router, ActivatedRoute } from '@angular/router';
import socket from '../socket';
import util from  '../util';
import emitter from '../event';
import { ThrowStmt } from '@angular/compiler'; 
import { UserProductsCheckoutPage } from './user-products-checkout/user-products-checkout.page';
import * as Sentry from '@sentry/angular-ivy';

declare const document: any;
let self;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit { 
  public isToggleMenu: any = false;
  public user  = this.loginService.user;
  public page  = this.loginService.page;
  public signUpClass: string = "";
  public loadingReset: any = false;


  constructor(
    public dataService: DataService,
    public loginService: LoginService,
    public router: Router,
    public modalController: ModalController,
    public alertController: AlertController,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
 
 

    this.route.queryParams.subscribe(params => {
      if (params['email']){
        this.user.email = params['email']
      }
      if (params['email'] && params['password']) { 
        this.user.email = params['email'];
        this.user.password = params['password'];
        this.loginService.submitLogin()
        this.router.navigate([], {
          queryParams: {
            'email': null,
            'password': null 
          },
          queryParamsHandling: 'merge'
        });
      }
    });





    
    self = this;

    console.log("Handling token...")
    this.loginService.handleToken(); 

    window.addEventListener('resize', () => {
      console.log(window.innerWidth)
      emitter.emit('resize-window');
      if (window.innerWidth <= 1024) {
        self.isToggleMenu = false;
      }
    });



    const exitHandler = () => {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        console.log(2222222222)
        this.dataService.isFullScreen = false
      }
    }


    if (document.addEventListener)
      {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
    

    console.log("setting",console.log(this.dataService.setting))
    if(!this.dataService.currentUser.role){
      this.loginService.getSetting();
    }

  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }


  signUp(dynamicClass){
    this.signUpClass= dynamicClass;
  }


  async sendResetCode(){ 
    if (this.loadingReset || !this.user.email) {
      return;
    }
    this.loadingReset = true

    try {
      const res = await axios.post(config.api.resetPassword,{
        email: this.user.email,
      });
      this.loginService.show2FA = true;  
      this.loginService.userId = res.data.userId;  
      
      alert("A login code has been sent to your mobile. If you did not receive a login code please contact support.")

    } catch(e) {
      console.error('Error resetting password:', e);
      this.loginService.formError = e;
      if(axios.isAxiosError(e) && e.response.status === 400) {
        if(e.response.data?.error_code === '60003') {
          alert('You have attempted to reset your password too many times in the last hour. Please try again later.')
        }
        else if (e.response.data?.message) {
          alert(e.response.data.message)
        } else {
          alert("Invalid email address. Please check your email and try again.")
        }
      } else {
        alert("An error occurred. Please try again.")
        Sentry.captureException(e);
      }

    } finally {
      this.loadingReset = false
    }
  }
 


  switchPage(name) {
    this.page = name;
  }




  openChat(){
    
    this.router.navigate([`/tabs/chats`]);
  }

  openCourses(){
    
    this.router.navigate([`/admin/courses`]);
  }

  goToProfile() {
    this.router.navigate([`/profile`]);
  }

  


  async checkout() {
    const modal = await this.modalController.create({
      component: UserCheckoutPage,
      breakpoints: [0, 0.5, 1],
      initialBreakpoint: 0.5,
      
    });
    await modal.present();
  }
  
  async openCheckout() {
    if (!this.dataService.order.products.length) {
      this.noProductAdded()

      return;
    }
    const modal = await this.modalController.create({
    component: UserProductsCheckoutPage,
    });

    await modal.present();
    const data = await modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data) {
      }
      console.log('dataReturned', dataReturned);
    });
  }

  async noProductAdded() {
    const alert = await this.alertController.create({
      header: 'No products are added.', 
      message: 'To add a product to the cart you can click on a product, then click the "Add to cart" button on the top right of the product. Once you have added the product you can checkout.',
      buttons: ['OK']
    });
  
    await alert.present();
  }


  async fullscreenCheckout() {
    const modal = await this.modalController.create({
      component: UserCheckoutPage,
      breakpoints: [1],
      initialBreakpoint: 1,
      
    });
    await modal.present();
   }


   toggleMenu() {
     if (window.innerWidth > 1024) {
      this.isToggleMenu = !this.isToggleMenu
     }
   }
  
}
